<template>
  <RouterView/>
</template>

<script>
import store from '@/store';

export default {
  name: 'Users',
  data: () => ({
    show: false,
  }),
  beforeRouteEnter(to, from, next) {
    if (store.getters['auth/getPermissions'].can_view_users_page) {
      next();
      return;
    }
    next(from);
  },
  mounted() {
    this.show = true;
  },
};
</script>

<style scoped>

</style>
